.gm-style .gm-style-iw-c {
  border-radius: 0;
  width: 350px;
  padding: 0;
  max-width: 400px !important;
}

.gm-style-iw-d {
  padding: 8px 16px 20px;
}

* .gm-style-iw-chr {
  width: -moz-fit-content;
  width: fit-content;
  position: absolute;
  right: 5px;
}

@media screen and (min-width: 768px) {
  .gm-style .gm-style-iw-c {
    width: 400px;
  }

  .mapSlider .slick-slide > div {
    margin: 0 25px;
  }
}

@media screen and (min-width: 1024px) {
  .mapSlider .slick-slide > div {
    margin: 0 20px;
  }

  .mapSlider button:first-of-type {
    left: 0;
  }

  .mapSlider button:last-of-type {
    right: 0;
  }
}

@media screen and (min-width: 1440px) {
  .mapSlider .slick-slide > div {
    margin: 0 45px;
  }

  .mapSlider button:first-of-type {
    left: 0;
  }

  .mapSlider button:last-of-type {
    right: 0;
  }
}

.MuiOutlinedInput-root.Mui-focused:not(.Mui-error) .MuiOutlinedInput-notchedOutline {
  border-color: var(--linkfarbe) !important;
}

.MuiFormLabel-root.Mui-focused:not(.Mui-error) {
  color: var(--linkfarbe) !important;
}

.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: var(--linkfarbe);
}

.MuiOutlinedInput-input {
  color: var(--secondary-1) !important;
}

.MuiMenu-paper {
  border-radius: var(--border-radius-sm) !important;
  margin-top: 4px !important;
}

.MuiMenu-list {
  max-height: 400px;
}

.MuiMenuItem-root {
  color: var(--secondary-1) !important;
  font-family: Inter, Arial, sans-serif !important;
  font-size: var(--font-size-md) !important;
  padding: var(--zbx-spacing-3) var(--zbx-spacing-4) !important;
  min-height: 4.25rem !important;
}

.MuiMenuItem-root:not(:last-child) {
  border-bottom: 1px solid var(--secondary-2) !important;
}

.MuiMenuItem-root:not(.Mui-selected), .MuiMenuItem-root:not(.Mui-selected) {
  background-color: var(--primary-3) !important;
}

.MuiMenuItem-root:hover {
  background-color: var(--secondary-4) !important;
}

.MuiMenuItem-root.Mui-selected, .MuiMenuItem-root.Mui-selected:hover {
  background-color: var(--secondary-3) !important;
}

[class*="directionsAndContactForm"] .MuiOutlinedInput-notchedOutline {
  border: 1px solid rgba(0, 0, 0, .23) !important;
}

.LocationInputPopper .MuiAutocomplete-listbox {
  white-space: nowrap !important;
  background-color: #fff !important;
  border-radius: 4px !important;
  min-width: 100% !important;
  padding: 0 !important;
  position: absolute !important;
}

.MuiOutlinedInput-notchedOutline {
  border: none !important;
}

.MuiAutocomplete-input {
  color: var(--primary-2) !important;
  padding: 7.5px 4px 7.5px 19px !important;
}

.MuiAutocomplete-root .MuiOutlinedInput-input {
  color: var(--primary-2) !important;
}

.MuiAutocomplete-option {
  transition: background-color .3s;
  font-size: var(--font-size-2xl) !important;
  font-family: var(--zbx-font-lato) !important;
  letter-spacing: normal !important;
  border-bottom: 1px solid var(--secondary-2) !important;
  min-height: 4.25rem !important;
  font-style: normal !important;
  font-weight: 300 !important;
  font-stretch: normal !important;
  line-height: 1.25 !important;
}

.MuiAutocomplete-option b {
  font-weight: 400 !important;
}

.MuiAutocomplete-option.Mui-focused {
  background-color: #e8e8e8 !important;
}

.MuiAutocomplete-listbox {
  box-shadow: -1px 7px 39px -14px rgba(0, 0, 0, .75);
}

.MuiAutocomplete-noOptions {
  letter-spacing: normal;
  font-style: normal;
  color: var(--primary-2) !important;
  font-family: var(--zbx-font-lato) !important;
  font-size: var(--font-size-2xl) !important;
  padding: 1.2rem 1rem !important;
  font-weight: 300 !important;
  font-stretch: normal !important;
  line-height: 1.25 !important;
}

.MuiAutocomplete-paper {
  margin-top: .8rem !important;
}

@media (max-width: 768px) {
  .LocationInputPopper .MuiAutocomplete-listbox {
    white-space: normal !important;
  }
}

@media (max-width: 600px) {
  .MuiAutocomplete-listbox {
    position: relative;
    white-space: normal !important;
  }
}

.MuiAutocomplete-option:has(p) {
  font-size: var(--font-size-base);
  border-bottom: 1px solid var(--secondary-2);
  color: rgba(0, 0, 0, .6);
  margin: 0;
  background-color: var(--secondary-4) !important;
  cursor: default !important;
  height: 15px !important;
  max-height: 15px !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.MuiAutocomplete-option p {
  height: -moz-fit-content;
  height: fit-content;
  margin: 0;
  padding: 0;
}

.MuiAutocomplete-option:has(p):hover {
  background-color: var(--secondary-4) !important;
}

@layer theme;

:root {
  --color-primary-1: #cb2c30;
  --linkfarbe: #ebc917;
  --highlight-color: #ffde01;
  --gold: #d6ab1a;
  --goldBtn: #ffde01;
  --grid-columns-1: 1fr;
  --grid-columns-2: repeat(2, 1fr);
  --grid-columns-3: repeat(3, 1fr);
  --grid-columns-4: repeat(4, 1fr);
  --grid-columns-6: repeat(6, 1fr);
  --grid-rows-1: 1fr;
  --grid-rows-2: repeat(2, 1fr);
  --grid-rows-3: repeat(3, 1fr);
  --grid-rows-4: repeat(4, 1fr);
  --grid-gap: .3125rem;
  --grid-gap-small: .5rem;
  --grid-gap-medium: 1rem;
  --grid-gap-medium-plus: 1.25rem;
  --grid-gap-large: 1.5rem;
  --grid-gap-xlarge: 2rem;
  --grid-template-areas-header-main-footer: "header" "main" "footer";
  --primary-1: #ffde01;
  --primary-2: #202020;
  --primary-2-translucent: "rgb(32, 32, 32, 0.41)";
  --primary-3: #fff;
  --primary-4: rgba(0, 0, 0, .26);
  --primary-5: #000;
  --secondary-1: #707070;
  --secondary-2: #d2d2d2;
  --secondary-3: #f0f0f0;
  --secondary-4: #f7f7f7;
  --secondary-5: #fbfcfd;
  --secondary-6: #979797;
  --footer: #000;
  --border-radius-sm: 4px;
  --border-radius-md: 10px;
  --border-radius-lg: 16px;
  --border-radius-xl: 26px;
  --breakpoint-xs: 520px;
  --breakpoint-sm: 768px;
  --breakpoint-md: 1024px;
  --breakpoint-lg: 1280px;
  --breakpoint-xl: 1640px;
  --z-index-nav: 50;
  --z-index-nav-second-level: 40;
  --z-index-above-nav: 60;
  --z-index-modal-bg: 999;
  --z-index-modal: 1000;
  --z-index-hero-base: -1;
  --z-index-hero-overlay: 0;
  --z-index-hero-content: 1;
  --z-index-floating-button: 10;
  --z-index-floating-button-desktop: 20;
  --z-index-gallery-arrows: 9;
  --bg-floating-button: #1f1f1f;
  --bg-image-zebra: url("/zebra-texture.svg");
  --faq-border: 1px solid #dededc;
  --faq-bg: #f5f5f5;
  --font-family-roboto: Roboto, sans-serif;
  --font-family-Inter: Inter, Arial, sans-serif;
  --font-size-sm: .75rem;
  --font-size-base: .875rem;
  --font-size-md: 1rem;
  --font-size-lg: 1.125rem;
  --font-size-xl: 1.375rem;
  --font-size-2xl: 1.5rem;
  --font-size-28px: 1.75rem;
  --font-size-3xl: 2rem;
  --font-size-35px: 2.1875rem;
  --font-size-38px: 2.375rem;
  --font-size-3xl-plus: 2.1875rem;
  --font-size-4xl: 2.5rem;
  --font-size-45px: 2.813rem;
  --font-size-4xl-plus: 3rem;
  --font-size-5xl: 3.5rem;
  --font-size-5xl-plus: 3.75rem;
  --font-size-6xl: 4rem;
  --margin-xs: .25rem;
  --margin-sm: .5rem;
  --margin-md: 1rem;
  --margin-lg: 1.5rem;
  --margin-lg-plus: 1.75rem;
  --margin-xl: 2rem;
  --margin-10: 2.5rem;
  --margin-12: 3rem;
  --margin-14: 3.5rem;
  --margin-16: 4rem;
  --zbx-spacing-1: .25rem;
  --zbx-spacing-1-5px: .3125;
  --zbx-spacing-2: .5rem;
  --zbx-spacing-3: .75rem;
  --zbx-spacing-3-extra: .625rem;
  --zbx-spacing-3-plus: .875rem;
  --zbx-spacing-4: 1rem;
  --zbx-spacing-5: 1.25rem;
  --zbx-spacing-5-plus: 1.375rem;
  --zbx-spacing-6: 1.5rem;
  --zbx-spacing-7: 1.75rem;
  --zbx-spacing-8: 2rem;
  --zbx-spacing-9: 2.25rem;
  --zbx-spacing-10: 2.5rem;
  --zbx-spacing-12: 3rem;
  --zbx-spacing-13: 3.25rem;
  --zbx-spacing-14: 3.5rem;
  --zbx-spacing-14-plus: 3.75rem;
  --zbx-spacing-16: 4rem;
  --zbx-shadow-1: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12);
  --zbx-shadow-2: 3px 1px 4px rgba(0, 0, 0, .1), 0 2px 2px 0 rgba(0, 0, 0, .12), 0 1px 5px 0 rgba(0, 0, 0, .15);
  --zbx-shadow-3: 0 0 0 0 rgba(0, 0, 0, .2), 0 0 0 0 rgba(0, 0, 0, .14), 0 0 0 0 rgba(0, 0, 0, .12);
  --zbx-overlay-bg: rgba(0, 0, 0, .66);
  --zbx-blur-1: 3px;
  --font-weight-light: 300;
  --font-weight-normal: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --line-height-sm: 1.25;
  --line-height-md: 1.5;
  --line-height-lg: 1.75;
  --line-height-xl: 2;
  --line-height-2xl: 2.25;
  --line-height-3xl: 2.5;
  --header-height: 115px;
  --header-height-withToolbar: 176px;
}

html {
  width: 100%;
  position: relative;
}

body {
  background-color: var(--primary-3);
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  margin: auto;
  position: relative;
}

.veBackgroundColor {
  background-color: var(--secondary-5);
}

h1, h2, h3, h4, h5, h6 {
  font-family: var(--zbx-font-kievit);
  letter-spacing: normal;
  color: var(--primary-2);
  font-stretch: normal;
  line-height: normal;
}

h1 {
  font-size: 64px;
}

@media (max-width: 599px) {
  h1 {
    font-size: 50px;
  }
}

h2 {
  font-size: 48px;
}

@media (max-width: 599px) {
  h2 {
    font-size: 35px;
  }
}

h3 {
  font-size: 40px;
}

@media (max-width: 599px) {
  h3 {
    font-size: 32px;
  }
}

h4 {
  font-size: 32px;
}

@media (max-width: 599px) {
  h4 {
    font-size: 28px;
  }
}

h5 {
  font-size: 24px;
}

@media (max-width: 599px) {
  h5 {
    font-size: 24px;
  }
}

h6 {
  font-size: 22px;
}

@media (max-width: 599px) {
  h6 {
    font-size: 16px;
  }
}

p {
  color: var(--primary-2);
  font-family: var(--zbx-font-lato);
  letter-spacing: normal;
  word-wrap: break-word;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  font-stretch: normal;
  line-height: 1.22;
}

b, strong {
  font-weight: 600;
}

.ve-styling {
  margin: 0 auto !important;
  padding: 0 !important;
}

.ve-hideElement {
  display: none !important;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

.custom-scrollbar .ps__rail-x {
  opacity: 1 !important;
  background-color: var(--secondary-2) !important;
  border-radius: var(--border-radius-md) !important;
  height: var(--zbx-spacing-3-plus) !important;
}

.custom-scrollbar .ps__rail-y {
  background-color: var(--secondary-2) !important;
  border-radius: var(--border-radius-md) !important;
  width: var(--zbx-spacing-3-plus) !important;
}

.custom-scrollbar .ps__thumb-x {
  background-color: var(--linkfarbe) !important;
  border-radius: var(--border-radius-md) !important;
  height: var(--zbx-spacing-3-plus) !important;
  min-height: var(--zbx-spacing-3-plus) !important;
  bottom: 0 !important;
}

.custom-scrollbar .ps__thumb-y {
  right: 0;
  background-color: var(--linkfarbe) !important;
  border-radius: var(--border-radius-md) !important;
  width: var(--zbx-spacing-3-plus) !important;
  min-width: var(--zbx-spacing-3-plus) !important;
}

.custom-scrollbar .ps__rail-x:hover .ps__thumb-x, .custom-scrollbar .ps__thumb-x:hover {
  height: var(--zbx-spacing-3-plus) !important;
}

.custom-scrollbar .ps__rail-y:hover .ps__thumb-y, .custom-scrollbar .ps__thumb-y:hover {
  width: var(--zbx-spacing-3-plus) !important;
}

.custom-scrollbar {
  padding-bottom: var(--zbx-spacing-8);
  scrollbar-width: auto;
  overflow-x: auto;
  max-width: 90vw !important;
}

.custom-scrollbar table {
  border-collapse: collapse;
  white-space: nowrap;
}

.custom-scrollbar tr {
  border-bottom: var(--faq-border);
}

.custom-scrollbar p {
  width: -moz-fit-content;
  width: fit-content;
  margin: 6px;
}

sup {
  top: -.5em;
}

sub {
  bottom: -.25em;
}

.removeUnderline a {
  text-decoration: none;
}

.slick-slide > div {
  margin: 0 15px;
  padding-bottom: 45px;
}

@media only screen and (min-width: 1024px) {
  .slick-slide > div {
    height: 95%;
    padding-bottom: 50px;
  }
}

.slick-list > * {
  box-sizing: content-box;
}

.slick-list {
  padding-bottom: 20px;
}

.slick-dots {
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0);
  background: none;
  border: 0;
  outline: none;
  flex-direction: row;
  justify-content: center;
  gap: 12px;
  width: 100%;
  height: 20px;
  margin-bottom: 1.5rem;
  padding: 5px;
  font-size: 0;
  line-height: 0;
  position: relative;
  display: flex !important;
  bottom: 0 !important;
}

ul.slick-dots {
  padding: 0;
}

.slick-dots li button {
  background-color: var(--secondary-2);
  color: var(--primary-2);
  content: "";
  cursor: pointer;
  border: none;
  border-radius: 99px;
  width: 8px;
  height: 8px;
  margin: 0;
  padding: 5px;
  font-size: 0;
  transition: all .1s ease-in-out;
}

.slick-dots li.slick-active button {
  background-color: var(--primary-1);
  transition: all .1s ease-in-out;
}

.divider-container {
  padding: .75rem 1.25rem;
  display: flex;
}

.divider {
  border: 0 solid rgba(0, 0, 0, .12);
  border-top-width: thin;
  flex: 0 0 83%;
}

.zb-druxt-entity-paragraph-margin-bottom {
  margin-bottom: var(--zbx-spacing-8);
}

@media (max-width: 600px) {
  .zb-druxt-entity-paragraph-margin-bottom {
    margin-bottom: var(--zbx-spacing-6);
  }
}

@media (min-width: 600px) {
  :root {
    --grid-gap: 1.25rem;
  }
}

@media only screen and (max-width: 600px) {
  .table-wrapper {
    overflow-x: scroll;
  }

  .xsDisplayNone {
    display: none !important;
  }
}

@media only screen and (max-width: 765px) {
  .smDisplayNone {
    display: none !important;
  }
}

@media only screen and (max-width: 1024px) {
  .mdDisplayNone {
    display: none !important;
  }
}

@media only screen and (max-width: 1200px) {
  .lgDisplayNone {
    display: none !important;
  }
}

@media only screen and (max-width: 1280px) {
  .xlDisplayNone {
    display: none !important;
  }
}

@media only screen and (min-width: 766px) {
  .hideSmAbove {
    display: none !important;
  }
}

.yarl__flex_center {
  place-content: center;
  display: flex;
  align-items: stretch !important;
}

.appGallery .slick-slide > div {
  margin: 0;
  padding: 4px;
}

.zbRatings .slick-dots li.slick-active button {
  background-color: var(--gold);
}

.zbRatings .slick-dots {
  margin-bottom: 0;
}

.zbRatings .slick-slide > div {
  margin: 0;
  padding: 0;
}

.zb-page-title-el__text, .zb-page-title-el__text p {
  font-family: var(--zbx-font-lato);
  font-size: var(--font-size-2xl);
  font-style: normal;
  font-stretch: normal;
  font-weight: var(--font-weight-light);
  letter-spacing: normal;
  line-height: var(--line-height-sm);
}

[class*="__checklist__content"] [class*="imageContainer"] {
  transition: all .2s ease-in-out;
  transform: scale(1.3);
}

[class*="__checklist__content"] [class*="imageContainer"]:hover {
  transform: scale(1.5);
}

[class*="__checklist__content"] [class*="cardText"] {
  text-align: center;
}

[class*="__checklist__content"] [class*="cardContainer"] {
  justify-content: flex-start;
  position: relative;
}

[class*="HeaderScrolled"] [class*="searchOverlay"] {
  top: .4rem;
}

[class*="SearchInputAutocomplete"] .MuiInputBase-root {
  padding-left: var(--zbx-spacing-12) !important;
}

[class*="SearchInputAutocomplete"] .MuiAutocomplete-root {
  padding-top: 6px;
  padding-bottom: 6px;
}

.SearchInputPopper .MuiAutocomplete-noOptions {
  display: none;
}

.SearchInputPopper .MuiAutocomplete-listbox {
  max-height: 13rem;
  margin-top: 1px;
}

.MuiAutocomplete-option a {
  color: inherit;
  text-decoration: none;
}

.SearchInputPopper .MuiPaper-root {
  margin-top: 0 !important;
}

@media (max-width: 600px) {
  [class*="HeaderScrolled"] [class*="searchOverlay"] {
    padding-top: .75rem;
    top: 0;
  }
}

[class*="LocationInputContainer"] .MuiInputBase-root {
  padding-right: var(--zbx-spacing-10) !important;
}

[class*="faqsContainer"] [class$="faq"] {
  padding: var(--zbx-spacing-3) var(--zbx-spacing-2);
}

[class*="faqsContainer"] [class*="header"] {
  margin: var(--zbx-spacing-10) 0 var(--zbx-spacing-7) 0;
  padding: 0;
}

[class*="faqsContainer"] [class*="subtitle"] {
  text-align: left;
  font-family: var(--font-family-heading);
  font-size: var(--font-size-3xl);
  font-weight: var(--font-weight-bold);
}

[class*="faqsContainer"] [class*="root"] {
  padding: 0;
}

[class*="faqsContainer"] [class*="accordion"] {
  max-width: 100%;
}

@media (max-width: 768px) {
  [class*="faqsContainer"] [class$="faq"] {
    width: 90%;
  }
}

@media (max-width: 960px) {
  [class*="faqsContainer"] [class*="accordion"] {
    padding-left: 0;
    padding-right: 0;
  }
}

[class*="gridPackagingMaterialContainer"] [class*="rt-r-gap"] {
  gap: var(--zbx-spacing-1);
}

[class*="gridPackagingMaterialContainer"] [class*="cardImage"] {
  height: auto;
}

[class*="gridPackagingMaterialContainer"] [class*="cardText"] {
  text-align: center;
  font-weight: var(--font-weight-normal);
}

@media (max-width: 768px) {
  [class*="gridPackagingMaterialContainer"] [class*="cardContainer"] {
    margin: 0;
    padding: 0;
  }

  [class*="faqsContainer"] [class$="faq"] {
    width: 95%;
  }
}

@media (max-width: 960px) {
  [class*="faqsContainer"] [class*="accordion"] {
    padding-left: 0;
    padding-right: 0;
  }
}

[class*="hero-module"] [class*="unstyledLink"] {
  color: var(--primary-3);
}

@media only screen and (max-width: 767px) {
  [class*="vcquickstart-module"] .slick-slide > div {
    height: 90%;
  }
}

#CybotCookiebotDialogNav .CybotCookiebotDialogNavItemLink.CybotCookiebotDialogActive {
  border-bottom: 4px solid var(--primary-1) !important;
  color: #141414 !important;
}

#CybotCookiebotDialogNav .CybotCookiebotDialogNavItemLink:hover {
  color: #000 !important;
  border-bottom: 4px solid rgba(255, 222, 1, .5) !important;
}

#CybotCookiebotDialog input:checked + .CybotCookiebotDialogBodyLevelButtonSlider {
  background-color: var(--primary-1) !important;
}

#CybotCookiebotDialog .CookieCard .CybotCookiebotDialogDetailBodyContentCookieContainerButton {
  text-shadow: 0 0 2px rgba(0, 0, 0, 0) !important;
}

#CybotCookiebotDialog .CookieCard .CybotCookiebotDialogDetailBodyContentCookieContainerButton:hover {
  color: var(--primary-1) !important;
  text-shadow: 0 0 2px #000 !important;
  border-bottom: 4px solid rgba(0, 0, 0, 0) !important;
}

#CybotCookiebotDialog .CookieCard .CybotCookiebotDialogDetailBodyContentIABv2Tab {
  text-shadow: 0 0 2px rgba(0, 0, 0, 0) !important;
}

#CybotCookiebotDialog .CookieCard .CybotCookiebotDialogDetailBodyContentIABv2Tab:hover {
  color: var(--primary-1) !important;
  text-shadow: 0 0 2px #000 !important;
  border-bottom: 4px solid rgba(0, 0, 0, 0) !important;
}

#CybotCookiebotDialog .CookieCard .CybotCookiebotDialogDetailBodyContentCookieProvider:not(.CybotCookiebotDialogDetailBodyContentCookieInfoCount) {
  text-shadow: 0 0 2px rgba(0, 0, 0, 0) !important;
}

#CybotCookiebotDialog .CookieCard .CybotCookiebotDialogDetailBodyContentCookieProvider:not(.CybotCookiebotDialogDetailBodyContentCookieInfoCount):hover {
  color: var(--primary-1) !important;
  text-shadow: 0 0 2px #000 !important;
  border-bottom: 4px solid rgba(0, 0, 0, 0) !important;
}

#CybotCookiebotDialog #CybotCookiebotDialogBodyContentText a {
  color: #000 !important;
}

#CybotCookiebotDialog #CybotCookiebotDialogBodyContentText a:hover {
  border-bottom: 4px solid var(--primary-1) !important;
}

#CybotCookiebotDialog #CybotCookiebotDialogBodyLevelButtonIABHeaderViewPartnersLink {
  color: #000 !important;
}

#CybotCookiebotDialog #CybotCookiebotDialogBodyLevelButtonIABHeaderViewPartnersLink:hover {
  border-bottom: 4px solid var(--primary-1) !important;
}

#CybotCookiebotDialog #CybotCookiebotDialogDetailBulkConsentList dt a {
  color: #000 !important;
}

#CybotCookiebotDialog #CybotCookiebotDialogDetailBulkConsentList dt a:hover {
  border-bottom: 4px solid var(--primary-1) !important;
}

#CybotCookiebotDialog #CybotCookiebotDialogDetailFooter a {
  color: #000 !important;
}

#CybotCookiebotDialog #CybotCookiebotDialogDetailFooter a:hover {
  border-bottom: 4px solid var(--primary-1) !important;
}

#CybotCookiebotDialog .CybotCookiebotDialogBodyLevelButtonIABDescription a {
  color: #000 !important;
}

#CybotCookiebotDialog .CybotCookiebotDialogBodyLevelButtonIABDescription a:hover {
  border-bottom: 4px solid var(--primary-1) !important;
}

#CybotCookiebotDialog .CybotCookiebotDialogDetailBodyContentCookieLink {
  color: #000 !important;
}

#CybotCookiebotDialog .CybotCookiebotDialogDetailBodyContentCookieLink:hover {
  border-bottom: 4px solid var(--primary-1) !important;
}

#CybotCookiebotDialog #CybotCookiebotDialogDetailBodyContentTextAbout a {
  color: #000 !important;
}

#CybotCookiebotDialog #CybotCookiebotDialogDetailBodyContentTextAbout a:hover {
  border-bottom: 4px solid var(--primary-1) !important;
}

#CybotCookiebotDialogFooter .CybotCookiebotDialogBodyButton {
  background-color: var(--primary-1) !important;
  border: 2px solid var(--primary-1) !important;
  color: #141414 !important;
}

#CookiebotWidget .CookiebotWidget-body .CookiebotWidget-consents-list li.CookiebotWidget-approved svg {
  fill: var(--primary-1) !important;
}

#CookiebotWidget .CookiebotWidget-consent-details button {
  color: var(--primary-1) !important;
}

#CookiebotWidget #CookiebotWidget-buttons button {
  color: #000 !important;
  background-color: var(--primary-1) !important;
  border-color: var(--primary-1) !important;
}

.CookieDeclaration p {
  font-family: var(--zbx-font-lato);
  font-size: var(--font-size-lg);
  font-style: normal;
  font-stretch: normal;
  font-weight: var(--font-weight-light);
  letter-spacing: normal;
  word-wrap: break-word;
  color: var(--primary-2);
  line-height: 1.22;
}

.CookieDeclaration th {
  font-family: var(--zbx-font-lato);
  font-size: var(--font-size-lg);
  font-style: normal;
  font-stretch: normal;
  font-weight: var(--font-weight-light);
  letter-spacing: normal;
  word-wrap: break-word;
  color: var(--primary-2);
  line-height: 1.22;
}

.CookieDeclaration td {
  font-family: var(--zbx-font-lato);
  font-size: var(--font-size-lg);
  font-style: normal;
  font-stretch: normal;
  font-weight: var(--font-weight-light);
  letter-spacing: normal;
  word-wrap: break-word;
  color: var(--primary-2);
  line-height: 1.22;
}

.CookieDeclaration p {
  margin: 0 0 var(--zbx-spacing-4) 0;
}

.CookieDeclaration b {
  font-weight: var(--font-weight-bold);
  padding: 6px;
}

.CookieDeclaration strong {
  font-weight: var(--font-weight-bold);
  padding: 6px;
}

.CookieDeclaration th {
  font-weight: var(--font-weight-bold);
  padding: 6px;
}

.CookieDeclaration a {
  color: var(--gold);
  font-weight: var(--font-weight-bold);
  text-decoration: none;
}

.CookieDeclaration a:first-letter {
  text-transform: capitalize;
}

.CookieDeclaration a:hover {
  text-underline-offset: 1px;
  text-underline-position: under;
  text-decoration: underline;
}

.cookiesTitle {
  margin: 0;
}

.cookiesPage {
  padding: 0 var(--zbx-spacing-5);
  margin-top: -60px;
}

.slick-slider {
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
  display: block;
  position: relative;
}

.slick-list {
  margin: 0;
  padding: 0;
  display: block;
  position: relative;
  overflow: hidden;
}

.slick-list:focus {
  outline: 0;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-list, .slick-slider .slick-track {
  transform: translate3d(0, 0, 0);
}

.slick-track {
  margin-left: auto;
  margin-right: auto;
  display: block;
  position: relative;
  top: 0;
  left: 0;
}

.slick-track:after, .slick-track:before {
  content: "";
  display: table;
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  border: 1px solid rgba(0, 0, 0, 0);
  height: auto;
  display: block;
}

.slick-arrow.slick-hidden {
  display: none;
}

.contactPopup-module__-3-e4a__overlay {
  background-color: var(--primary-3);
  border-radius: 4px;
  max-width: 540px;
  height: 250px;
  transition: all .1s ease-in-out;
  position: relative;
  top: 45%;
  left: 50%;
  overflow: scroll;
  transform: translate(-50%, -50%);
  box-shadow: 0 11px 15px -7px rgba(0, 0, 0, .2), 0 24px 38px 3px rgba(0, 0, 0, .14), 0 9px 46px 8px rgba(0, 0, 0, .12);
}

.contactPopup-module__-3-e4a__contact__button {
  width: 65px;
  height: 65px;
  color: var(--primary-3);
  cursor: pointer;
  font-size: var(--font-size-sm);
  text-transform: capitalize;
  background: none;
  border: none;
  border-radius: 0;
  padding: 0 1rem;
}

.contactPopup-module__-3-e4a__close__icon {
  color: var(--secondary-6);
}

.contactPopup-module__-3-e4a__close__icon path {
  stroke: currentColor;
}

.contactPopup-module__-3-e4a__close__button:hover .contactPopup-module__-3-e4a__close__icon {
  color: var(--secondary-1);
}

.contactPopup-module__-3-e4a__icon {
  width: 24px;
  height: 24px;
  fill: var(--primary-3);
}

.contactPopup-module__-3-e4a__contactPopup__wrapperPhone, .contactPopup-module__-3-e4a__contactPopup__wrapperEmail {
  min-width: 450px;
  height: inherit;
  flex-direction: column;
  gap: 1.25rem;
  margin: 0 auto;
  padding-left: 2rem;
  padding-right: 2rem;
  display: flex;
}

.contactPopup-module__-3-e4a__contactPopup__wrapperPhone div:last-child {
  padding-bottom: 1.25rem;
}

.contactPopup-module__-3-e4a__contactPopup__wrapperEmail {
  justify-content: center;
}

.contactPopup-module__-3-e4a__contactPopup__title {
  font-size: 1.75rem;
  font-weight: var(--font-weight-bold);
  text-align: center;
  color: var(--primary-2);
  font-family: var(--zbx-font-kievit);
  margin: .9375rem 0 0;
  padding-top: 1.0625rem;
  padding-bottom: 1.125rem;
}

.contactPopup-module__-3-e4a__location__title {
  overflow-wrap: break-word;
  width: 294px;
}

.contactPopup-module__-3-e4a__message__wrapper {
  flex-direction: column;
  gap: .75rem;
  display: flex;
}

p.contactPopup-module__-3-e4a__email__message {
  text-align: center;
  font-family: var(--zbx-font-lato);
  font-size: var(--font-size-lg);
  color: var(--secondary-1);
  line-height: 1.11;
  font-weight: var(--font-weight-normal);
  margin: 0 auto 1.125rem;
  padding: 0;
}

.contactPopup-module__-3-e4a__action_button__wrapper {
  justify-content: center;
  gap: 1.125rem;
  display: flex;
}

.contactPopup-module__-3-e4a__email__link {
  text-decoration: none;
}

.contactPopup-module__-3-e4a__phone__wrapper {
  font-family: var(--zbx-font-lato);
  font-size: var(--font-size-md);
  flex-direction: row;
  gap: .5rem;
  display: flex;
}

.contactPopup-module__-3-e4a__phone {
  color: var(--secondary-1);
  border-bottom: .5px solid var(--secondary-1);
  font-weight: normal;
  -webkit-text-decoration-line: none;
  text-decoration-line: none;
}

.contactPopup-module__-3-e4a__location__name {
  color: var(--primary-2-translucent);
  font-weight: bold;
}

.contactPopup-module__-3-e4a__close__button {
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
  position: absolute;
  top: 5%;
  right: 5%;
}

.contactPopup-module__-3-e4a__close__button:hover {
  cursor: pointer;
}

.contactPopup-module__-3-e4a__close__button:hover svg g g {
  stroke: var(--secondary-2);
}

@media (min-width: 600px) {
  .contactPopup-module__-3-e4a__contact__button {
    background: var(--primary-5);
    transition: width .2s ease-out, height .2s ease-out;
  }

  .contactPopup-module__-3-e4a__contact__button:hover {
    width: 75px;
    height: 75px;
  }

  p.contactPopup-module__-3-e4a__contactPopup__title {
    font-size: var(--font-size-3xl);
  }

  .contactPopup-module__-3-e4a__location__title {
    width: unset;
    overflow-wrap: unset;
  }
}

